<template>
  <div class="my-3 border rounded-lg bg-white" v-show="list.length > 0">
    <div class="lg:overflow-x-auto">
      <table class="table-auto w-full">
        <thead>
          <tr class="border-b h-14 rounded-lg bg-grey-header round">
            <th v-if="checkbox" class="pl-5 w-1">
              <div class="flex justify-center items-center -mt-3">
                <label v-if="isHalfData" class="ctnr">
                  <input type="checkbox" v-model="isHalfData" @click="resetClickAllData" />
                  <span class="strip" />
                </label>
                <label v-else class="ctnr">
                  <input type="checkbox" v-model="isAllData" @click="onClickAllData" />
                  <span class="checkmark" />
                </label>
              </div>
            </th>
            <th v-for="(item, index) in tableHeader" :key="index + Math.random()" :class="`text-sm text-${tableHeaderAlign[index]} ${!checkbox && index === 0 ? 'pl-5' : ''}`">
              {{ item }}
            </th>
            <th v-for="index in additionalColumn" :key="index + Math.random()" class="p-0" />
          </tr>
        </thead>
        <tbody>
          <tr class="border-b h-14 hover:bg-gray-100" v-for="(item, index) in list" :key="index + Math.random()">
            <td v-if="checkbox" class="pl-5 w-1">
              <div class="flex justify-center items-center -mt-3">
                <label class="ctnr">
                  <input type="checkbox" :value="item.valueParams" v-model="selectedData" @click="onClickWithShift($event, index, item.valueParams)" />
                  <span class="checkmark" />
                </label>
              </div>
            </td>
            <td :class="`text-sm text-${listAlign[0]} ${checkbox ? '' : 'pl-5'}`">
              {{ (tooltip !== undefined && tooltip[0]) || (secondTooltip !== undefined && secondTooltip[0]) || (textToAction !== undefined && textToAction[0]) ? undefined : item.firstColumn }}
              <div v-show="item.firstColumnDesc">
                <span class="text-xs text-neutral-500">{{ item.firstColumnDesc }}</span>
              </div>
              <TextToAction
                v-show="textToAction !== undefined && textToAction[0]"
                :text="item.firstColumn"
                :paramsText="item.firstColumnParams"
                :height="textToAction !== undefined && textToAction[0] ? textToAction[0].height : undefined"
                :width="textToAction !== undefined && textToAction[0] ? textToAction[0].width : undefined"
                :viewBox="textToAction !== undefined && textToAction[0] ? textToAction[0].viewBox : undefined"
                :size="textToAction !== undefined && textToAction[0] ? textToAction[0].size : undefined"
                :paths="textToAction !== undefined && textToAction[0] ? textToAction[0].paths : undefined"
                :action="textToAction !== undefined && textToAction[0] ? textToAction[0].action : undefined"
              />
              <TooltipTopLeft
                v-show="tooltip !== undefined && tooltip[0]"
                :text="item.firstColumn"
                :tooltipText="item.firstColumnTooltip ? item.firstColumnTooltip : item.firstColumn"
                :size="tooltipSize"
              />
              <TooltipTopRight
                v-show="secondTooltip !== undefined && secondTooltip[0]"
                :text="item.firstColumn"
                :tooltipText="item.firstColumnTooltip ? item.firstColumnTooltip : item.firstColumn"
                :size="tooltipSize"
              />
            </td>
            <td :class="`text-sm text-${listAlign[1]}`">
              {{ (tooltip !== undefined && tooltip[1]) || (secondTooltip !== undefined && secondTooltip[1]) || (textToAction !== undefined && textToAction[1]) ? undefined : item.secondColumn }}
              <div v-show="item.secondColumnDesc">
                <span class="text-xs text-neutral-500">{{ item.secondColumnDesc }}</span>
              </div>
              <TextToAction
                v-show="textToAction !== undefined && textToAction[1]"
                :text="item.secondColumn"
                :paramsText="item.secondColumnParams"
                :height="textToAction !== undefined && textToAction[1] ? textToAction[1].height : undefined"
                :width="textToAction !== undefined && textToAction[1] ? textToAction[1].width : undefined"
                :viewBox="textToAction !== undefined && textToAction[1] ? textToAction[1].viewBox : undefined"
                :size="textToAction !== undefined && textToAction[1] ? textToAction[1].size : undefined"
                :paths="textToAction !== undefined && textToAction[1] ? textToAction[1].paths : undefined"
                :action="textToAction !== undefined && textToAction[1] ? textToAction[1].action : undefined"
              />
              <TooltipTopLeft
                v-show="tooltip !== undefined && tooltip[1]"
                :text="item.secondColumn"
                :tooltipText="item.secondColumnTooltip ? item.secondColumnTooltip : item.secondColumn"
                :size="tooltipSize"
              />
              <TooltipTopRight
                v-show="secondTooltip !== undefined && secondTooltip[1]"
                :text="item.secondColumn"
                :tooltipText="item.secondColumnTooltip ? item.secondColumnTooltip : item.secondColumn"
                :size="tooltipSize"
              />
            </td>
            <td :class="`text-sm text-${listAlign[2]}`">
              {{ (tooltip !== undefined && tooltip[2]) || (secondTooltip !== undefined && secondTooltip[2]) || (textToAction !== undefined && textToAction[2]) ? undefined : item.thirdColumn }}
              <div v-show="item.thirdColumnDesc">
                <span class="text-xs text-neutral-500">{{ item.thirdColumnDesc }}</span>
              </div>
              <TextToAction
                v-show="textToAction !== undefined && textToAction[2]"
                :text="item.thirdColumn"
                :paramsText="item.thirdColumnParams"
                :height="textToAction !== undefined && textToAction[2] ? textToAction[2].height : undefined"
                :width="textToAction !== undefined && textToAction[2] ? textToAction[2].width : undefined"
                :viewBox="textToAction !== undefined && textToAction[2] ? textToAction[2].viewBox : undefined"
                :size="textToAction !== undefined && textToAction[2] ? textToAction[2].size : undefined"
                :paths="textToAction !== undefined && textToAction[2] ? textToAction[2].paths : undefined"
                :action="textToAction !== undefined && textToAction[2] ? textToAction[2].action : undefined"
              />
              <TooltipTopLeft
                v-show="tooltip !== undefined && tooltip[2]"
                :text="item.thirdColumn"
                :tooltipText="item.thirdColumnTooltip ? item.thirdColumnTooltip : item.thirdColumn"
                :size="tooltipSize"
              />
              <TooltipTopRight
                v-show="secondTooltip !== undefined && secondTooltip[2]"
                :text="item.thirdColumn"
                :tooltipText="item.thirdColumnTooltip ? item.thirdColumnTooltip : item.thirdColumn"
                :size="tooltipSize"
              />
            </td>
            <td :class="`text-sm text-${listAlign[3]}`">
              {{ (tooltip !== undefined && tooltip[3]) || (secondTooltip !== undefined && secondTooltip[3]) || (textToAction !== undefined && textToAction[3]) ? undefined : item.fourthColumn }}
              <div v-show="item.fourthColumnDesc">
                <span class="text-xs text-neutral-500">{{ item.fourthColumnDesc }}</span>
              </div>
              <TextToAction
                v-show="textToAction !== undefined && textToAction[3]"
                :text="item.fourthColumn"
                :paramsText="item.fourthColumnParams"
                :height="textToAction !== undefined && textToAction[3] ? textToAction[3].height : undefined"
                :width="textToAction !== undefined && textToAction[3] ? textToAction[3].width : undefined"
                :viewBox="textToAction !== undefined && textToAction[3] ? textToAction[3].viewBox : undefined"
                :size="textToAction !== undefined && textToAction[3] ? textToAction[3].size : undefined"
                :paths="textToAction !== undefined && textToAction[3] ? textToAction[3].paths : undefined"
                :action="textToAction !== undefined && textToAction[3] ? textToAction[3].action : undefined"
              />
              <TooltipTopLeft
                v-show="tooltip !== undefined && tooltip[3]"
                :text="item.fourthColumn"
                :tooltipText="item.fourthColumnTooltip ? item.fourthColumnTooltip : item.fourthColumn"
                :size="tooltipSize"
              />
              <TooltipTopRight
                v-show="secondTooltip !== undefined && secondTooltip[3]"
                :text="item.fourthColumn"
                :tooltipText="item.fourthColumnTooltip ? item.fourthColumnTooltip : item.fourthColumn"
                :size="tooltipSize"
              />
            </td>
            <td :class="`text-sm text-${listAlign[4]}`">
              {{ (tooltip !== undefined && tooltip[4]) || (secondTooltip !== undefined && secondTooltip[4]) || (textToAction !== undefined && textToAction[4]) ? undefined : item.fifthColumn }}
              <div v-show="item.fifthColumnDesc">
                <span class="text-xs text-neutral-500">{{ item.fifthColumnDesc }}</span>
              </div>
              <TextToAction
                v-show="textToAction !== undefined && textToAction[4]"
                :text="item.fifthColumn"
                :paramsText="item.fifthColumnParams"
                :height="textToAction !== undefined && textToAction[4] ? textToAction[4].height : undefined"
                :width="textToAction !== undefined && textToAction[4] ? textToAction[4].width : undefined"
                :viewBox="textToAction !== undefined && textToAction[4] ? textToAction[4].viewBox : undefined"
                :size="textToAction !== undefined && textToAction[4] ? textToAction[4].size : undefined"
                :paths="textToAction !== undefined && textToAction[4] ? textToAction[4].paths : undefined"
                :action="textToAction !== undefined && textToAction[4] ? textToAction[4].action : undefined"
              />
              <TooltipTopLeft
                v-show="tooltip !== undefined && tooltip[4]"
                :text="item.fifthColumn"
                :tooltipText="item.fifthColumnTooltip ? item.fifthColumnTooltip : item.fifthColumn"
                :size="tooltipSize"
              />
              <TooltipTopRight
                v-show="secondTooltip !== undefined && secondTooltip[4]"
                :text="item.fifthColumn"
                :tooltipText="item.fifthColumnTooltip ? item.fifthColumnTooltip : item.fifthColumn"
                :size="tooltipSize"
              />
            </td>
            <td :class="`text-sm text-${listAlign[5]}`">
              {{ (tooltip !== undefined && tooltip[5]) || (secondTooltip !== undefined && secondTooltip[5]) || (textToAction !== undefined && textToAction[5]) ? undefined : item.sixthColumn }}
              <div v-show="item.sixthColumnDesc">
                <span class="text-xs text-neutral-500">{{ item.sixthColumnDesc }}</span>
              </div>
              <TextToAction
                v-show="textToAction !== undefined && textToAction[5]"
                :text="item.sixthColumn"
                :paramsText="item.sixthColumnParams"
                :height="textToAction !== undefined && textToAction[5] ? textToAction[5].height : undefined"
                :width="textToAction !== undefined && textToAction[5] ? textToAction[5].width : undefined"
                :viewBox="textToAction !== undefined && textToAction[5] ? textToAction[5].viewBox : undefined"
                :size="textToAction !== undefined && textToAction[5] ? textToAction[5].size : undefined"
                :paths="textToAction !== undefined && textToAction[5] ? textToAction[5].paths : undefined"
                :action="textToAction !== undefined && textToAction[5] ? textToAction[5].action : undefined"
              />
              <TooltipTopLeft
                v-show="tooltip !== undefined && tooltip[5]"
                :text="item.sixthColumn"
                :tooltipText="item.sixthColumnTooltip ? item.sixthColumnTooltip : item.sixthColumn"
                :size="tooltipSize"
              />
              <TooltipTopRight
                v-show="secondTooltip !== undefined && secondTooltip[5]"
                :text="item.sixthColumn"
                :tooltipText="item.sixthColumnTooltip ? item.sixthColumnTooltip : item.sixthColumn"
                :size="tooltipSize"
              />
            </td>
            <td :class="`text-sm text-${listAlign[6]}`">
              {{ (tooltip !== undefined && tooltip[6]) || (secondTooltip !== undefined && secondTooltip[6]) || (textToAction !== undefined && textToAction[6]) ? undefined : item.seventhColumn }}
              <div v-show="item.seventhColumnDesc">
                <span class="text-xs text-neutral-500">{{ item.seventhColumnDesc }}</span>
              </div>
              <TextToAction
                v-show="textToAction !== undefined && textToAction[6]"
                :text="item.seventhColumn"
                :paramsText="item.seventhColumnParams"
                :height="textToAction !== undefined && textToAction[6] ? textToAction[6].height : undefined"
                :width="textToAction !== undefined && textToAction[6] ? textToAction[6].width : undefined"
                :viewBox="textToAction !== undefined && textToAction[6] ? textToAction[6].viewBox : undefined"
                :size="textToAction !== undefined && textToAction[6] ? textToAction[6].size : undefined"
                :paths="textToAction !== undefined && textToAction[6] ? textToAction[6].paths : undefined"
                :action="textToAction !== undefined && textToAction[6] ? textToAction[6].action : undefined"
              />
              <TooltipTopLeft
                v-show="tooltip !== undefined && tooltip[6]"
                :text="item.seventhColumn"
                :tooltipText="item.seventhColumnTooltip ? item.seventhColumnTooltip : item.seventhColumn"
                :size="tooltipSize"
              />
              <TooltipTopRight
                v-show="secondTooltip !== undefined && secondTooltip[6]"
                :text="item.seventhColumn"
                :tooltipText="item.seventhColumnTooltip ? item.seventhColumnTooltip : item.seventhColumn"
                :size="tooltipSize"
              />
            </td>
            <td :class="`text-sm text-${listAlign[7]}`">
              {{ (tooltip !== undefined && tooltip[7]) || (secondTooltip !== undefined && secondTooltip[7]) || (textToAction !== undefined && textToAction[7]) ? undefined : item.eighthColumn }}
              <div v-show="item.eighthColumnDesc">
                <span class="text-xs text-neutral-500">{{ item.eighthColumnDesc }}</span>
              </div>
              <TextToAction
                v-show="textToAction !== undefined && textToAction[7]"
                :text="item.eighthColumn"
                :paramsText="item.eighthColumnParams"
                :height="textToAction !== undefined && textToAction[7] ? textToAction[7].height : undefined"
                :width="textToAction !== undefined && textToAction[7] ? textToAction[7].width : undefined"
                :viewBox="textToAction !== undefined && textToAction[7] ? textToAction[7].viewBox : undefined"
                :size="textToAction !== undefined && textToAction[7] ? textToAction[7].size : undefined"
                :paths="textToAction !== undefined && textToAction[7] ? textToAction[7].paths : undefined"
                :action="textToAction !== undefined && textToAction[7] ? textToAction[7].action : undefined"
              />
              <TooltipTopLeft
                v-show="tooltip !== undefined && tooltip[7]"
                :text="item.eighthColumn"
                :tooltipText="item.eighthColumnTooltip ? item.eighthColumnTooltip : item.eighthColumn"
                :size="tooltipSize"
              />
              <TooltipTopRight
                v-show="secondTooltip !== undefined && secondTooltip[7]"
                :text="item.eighthColumn"
                :tooltipText="item.eighthColumnTooltip ? item.eighthColumnTooltip : item.eighthColumn"
                :size="tooltipSize"
              />
            </td>
            <td :class="`text-sm text-${listAlign[8]}`">
              {{ (tooltip !== undefined && tooltip[8]) || (secondTooltip !== undefined && secondTooltip[8]) || (textToAction !== undefined && textToAction[8]) ? undefined : item.ninthColumn }}
              <div v-show="item.ninthColumnDesc">
                <span class="text-xs text-neutral-500">{{ item.ninthColumnDesc }}</span>
              </div>
              <TextToAction
                v-show="textToAction !== undefined && textToAction[8]"
                :text="item.ninthColumn"
                :paramsText="item.ninthColumnParams"
                :height="textToAction !== undefined && textToAction[8] ? textToAction[8].height : undefined"
                :width="textToAction !== undefined && textToAction[8] ? textToAction[8].width : undefined"
                :viewBox="textToAction !== undefined && textToAction[8] ? textToAction[8].viewBox : undefined"
                :size="textToAction !== undefined && textToAction[8] ? textToAction[8].size : undefined"
                :paths="textToAction !== undefined && textToAction[8] ? textToAction[8].paths : undefined"
                :action="textToAction !== undefined && textToAction[8] ? textToAction[8].action : undefined"
              />
              <TooltipTopLeft
                v-show="tooltip !== undefined && tooltip[8]"
                :text="item.ninthColumn"
                :tooltipText="item.ninthColumnTooltip ? item.ninthColumnTooltip : item.ninthColumn"
                :size="tooltipSize"
              />
              <TooltipTopRight
                v-show="secondTooltip !== undefined && secondTooltip[8]"
                :text="item.ninthColumn"
                :tooltipText="item.ninthColumnTooltip ? item.ninthColumnTooltip : item.ninthColumn"
                :size="tooltipSize"
              />
            </td>
            <td :class="`text-sm text-${listAlign[9]}`">
              {{ (tooltip !== undefined && tooltip[9]) || (secondTooltip !== undefined && secondTooltip[9]) || (textToAction !== undefined && textToAction[9]) ? undefined : item.tenthColumn }}
              <div v-show="item.tenthColumnDesc">
                <span class="text-xs text-neutral-500">{{ item.tenthColumnDesc }}</span>
              </div>
              <TextToAction
                v-show="textToAction !== undefined && textToAction[9]"
                :text="item.tenthColumn"
                :paramsText="item.tenthColumnParams"
                :height="textToAction !== undefined && textToAction[9] ? textToAction[9].height : undefined"
                :width="textToAction !== undefined && textToAction[9] ? textToAction[9].width : undefined"
                :viewBox="textToAction !== undefined && textToAction[9] ? textToAction[9].viewBox : undefined"
                :size="textToAction !== undefined && textToAction[9] ? textToAction[9].size : undefined"
                :paths="textToAction !== undefined && textToAction[9] ? textToAction[9].paths : undefined"
                :action="textToAction !== undefined && textToAction[9] ? textToAction[9].action : undefined"
              />
              <TooltipTopLeft
                v-show="tooltip !== undefined && tooltip[9]"
                :text="item.tenthColumn"
                :tooltipText="item.tenthColumnTooltip ? item.tenthColumnTooltip : item.tenthColumn"
                :size="tooltipSize"
              />
              <TooltipTopRight
                v-show="secondTooltip !== undefined && secondTooltip[9]"
                :text="item.tenthColumn"
                :tooltipText="item.tenthColumnTooltip ? item.tenthColumnTooltip : item.tenthColumn"
                :size="tooltipSize"
              />
            </td>
            <td class="flex justify-center py-3 pr-4">
              <button
                v-for="(subitem, index) in actionButtons"
                :key="index + Math.random()"
                @click="subitem.next(item.firstQuery, item.secondQuery, item.thirdQuery, item.fourthQuery, item.fifthQuery)"
                :class="[
                  subitem.text
                    ? 'bg-yellow text-white text-xs font-bold rounded-md mx-1 py-1 px-3 border border-yellow hover:opacity-80 focus:outline-none'
                    : `rounded-md mx-1 border border-yellow hover:opacity-80 focus:outline-none ${subitem.additionalStyle ? subitem.additionalStyle : ''}`,
                  subitem.disabled ? 'border-grey-field cursor-not-allowed hover:border-grey-field' : ''
                ]"
                :disabled="subitem.disabled"
              >
                {{ subitem.text ? subitem.text : undefined }}
                <GeneralIcon v-show="!subitem.text" :height="subitem.height" :width="subitem.width" :viewBox="subitem.viewBox" :paths="subitem.paths" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
const TextToAction = () => import(/* webpackChunkName: "text-to-action" */ '@/components/Table/TextToAction')
const TooltipTopLeft = () => import(/* webpackChunkName: "tooltip-top-left" */ '@/components/Table/TooltipTopLeft')
const TooltipTopRight = () => import(/* webpackChunkName: "tooltip-top-right" */ '@/components/Table/TooltipTopRight')
const GeneralIcon = () => import(/* webpackChunkName: "general-icon" */ '@/components/Table/GeneralIcon')
export default {
  name: 'data-table',
  components: { TextToAction, TooltipTopLeft, TooltipTopRight, GeneralIcon },
  props: {
    tableHeader: { type: Array },
    tableHeaderAlign: { type: Array },
    checkbox: { type: Boolean, default: false },
    actionCheckbox: { type: Function, default: () => {} },
    resetSelectedData: { type: Boolean },
    textToAction: { type: Array },
    tooltip: { type: Array },
    secondTooltip: { type: Array },
    tooltipSize: { required: false },
    list: { type: Array },
    listAlign: { type: Array },
    actionButtons: { type: Array },
    counter: { required: true },
    totalPages: { required: false },
    totalItems: { required: false },
    size: { required: false }
  },
  data: () => ({
    selectedData: [],
    lastCheckedIdx: -1,
    isHalfData: false,
    isAllData: false
  }),
  watch: {
    counter: function () {
      this.isAllData = false
      this.selectedData = []
    },
    totalPages: function () {
      this.isAllData = false
      this.selectedData = []
    },
    resetSelectedData: function (val) {
      if (val) this.resetClickAllData()
    },
    selectedData: function () {
      this.selectedData.length > 0 && this.selectedData.length < this.list.length ? (this.isHalfData = true) : (this.isHalfData = false)
      this.selectedData.length === this.list.length ? (this.isAllData = true) : (this.isAllData = false)
      this.actionCheckbox(this.selectedData)
    }
  },
  computed: {
    additionalColumn: function () {
      let arr = []
      if (this.tableHeader) {
        for (let i = this.tableHeader.length; i < 11; i++) {
          arr.push(i)
        }
      }
      return arr
    }
  },
  methods: {
    onClickAllData() {
      this.selectedData = []
      this.isAllData = !this.isAllData
      if (this.isAllData) {
        for (let i = 0; i < this.list.length; i++) {
          this.selectedData.push(this.list[i].valueParams)
        }
      }
    },
    resetClickAllData() {
      this.selectedData = []
      this.isHalfData = false
    },
    onClickWithShift(event, idx, idFile) {
      var action = this.selectedData.indexOf(idFile) === -1 ? 'select' : 'deselect'
      if (event.shiftKey && this.lastCheckedIdx !== -1) {
        var start = this.lastCheckedIdx
        var end = idx - 1
        if (start > end) {
          start = idx + 1
          end = this.lastCheckedIdx
        }
        for (var c = start; c <= end; c++) {
          var currentIdFile = this.list[c]['valueParams']
          this.markSelectDeselect(action, currentIdFile)
        }
      }
      this.markSelectDeselect(action, idFile)
      this.lastCheckedIdx = idx
      if (this.selectedData.length === 0) {
        this.lastCheckedIdx = -1
      }
    },
    markSelectDeselect(action, idFile) {
      var currentPos = this.selectedData.indexOf(idFile)
      if (action === 'select' && currentPos === -1) {
        this.selectedData.push(idFile)
      } else if (action === 'deselect' && currentPos !== -1) {
        this.selectedData.splice(currentPos, 1)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/* The container */
.ctnr {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.ctnr input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark,
.strip {
  position: absolute;
  margin: 2px 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #888888;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.ctnr:hover input ~ .checkmark,
.ctnr:hover input ~ .strip {
  background-color: rgb(243, 244, 246);
}

/* When the checkbox is checked, add a blue background */
.ctnr input:checked ~ .checkmark,
.ctnr input:checked ~ .strip {
  background-color: #3b82f6;
  border: 1px solid #3b82f6;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after,
.strip:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.ctnr input:checked ~ .checkmark:after,
.ctnr input:checked ~ .strip:after {
  display: block;
}

/* Style the checkmark/indicator */
.ctnr .checkmark:after {
  left: 5px;
  top: 1px;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ctnr .strip:after {
  left: 3px;
  top: 7px;
  width: 70%;
  border: solid white;
  border-width: 0 3px 3px 0;
}
</style>
